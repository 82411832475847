import { useEffect, useState } from "react";
import DropdownArrow from "../reusable/DropdownArrow";
import FilterIcon from "../reusable/FilterIcon";
import FilterMenu from "./FilterMenu";
import { useNavigate, useParams } from "react-router-dom";
import ProspectList from "./ProspectList";
import ProspectTable from "./ProspectTable";
import { useGBatch } from "../../context/GBatchContext";
import { useKeys } from "../../context/KeysContext";

const GapiDashboard = () => {
  const {toggleSelectedInput} = useKeys()
  const { id: batchId } = useParams()
  // const {currentBatch: batch, setBatch, GETBatch} = useGBatch()
  const {GETBatch} = useGBatch()
  const [batch, setBatch] = useState(null)
  const navigate = useNavigate()

  const [prospects, setProspects] = useState(null)
  const [selectedProspect, setSelectedProspect] = useState(null)
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filters, setFilters] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')

  const filterByQuery = filteredProspects => {
    filteredProspects = !searchQuery
    ? filteredProspects
    : filteredProspects.filter(p => 
        p.gId.toString().includes(searchQuery) ||
        p.name.includes(searchQuery) ||
        p.website.includes(searchQuery) ||
        p.phone.includes(searchQuery) ||
        p.email.includes(searchQuery) ||
        p.facebook.includes(searchQuery) ||
        p.instagram.includes(searchQuery)
      );
      
      return filteredProspects
  }
  const filterByFilters = filteredProspects => {
    filteredProspects = !filters
    ? filteredProspects
    : filteredProspects.filter(p => {
      for(let att in filters){
        if(filters[att] !== null 
        && filters[att] == p.status[att])
          return true
      }
    })

    return filteredProspects
  }
  const filterProspects = () => {
    let filteredProspects = batch.prospects

    if(!filters && !searchQuery) {
      setProspects(filteredProspects)
      return
    }
    
    filteredProspects = filterByQuery(filteredProspects)
    filteredProspects = filterByFilters(filteredProspects)

    setProspects(filteredProspects)
  }

  const setRequestedBatch = async () => {
    let batch = await GETBatch(batchId)

    setBatch(batch)
    setProspects(batch.prospects)
  }

  useEffect(() => {
    setRequestedBatch()
  }, [])
  useEffect(() => {
    if(prospects)
      filterProspects()
  }, [filters, searchQuery, batch])

  useEffect(() => {
    // console.log(batch)
  }, [batch])

  return (
    <div className="dashboard">
      <div className="column-1">
        <div className="row">
          <DropdownArrow onClick={() => navigate('/gapi')} />
          <input 
            onChange={e => setSearchQuery(e.target.value.toLowerCase())}
            value={searchQuery}
            type="text" 
            placeholder="Search"
            onSelect={toggleSelectedInput}
            onBlur={toggleSelectedInput}
          />
          <FilterIcon onClick={ () => setShowFilterMenu(!showFilterMenu)} />
          <FilterMenu
            show={showFilterMenu}
            onChange={filters => setFilters(filters)}
          />
        </div>
        <ProspectList 
          prospects={prospects}
          onProspectClick={clickedProspect => setSelectedProspect(clickedProspect)}
        />
      </div>
      <ProspectTable prospectData={selectedProspect} />
    </div>
  );
}
 
export default GapiDashboard;


/*

  const prospectsData = [
    {
      id: 0,
      name: "MasXMenos",
      website: "https://masxmenos.com",
      phone: "88888888",
      email: "info@masxmenos.com",
      facebook: "facebook.masxmenos",
      instagram: "instagram.masxmenos",
      status: {
        interesting: true,
        seen: false,
        contacted: false
      },
    },
    {
      id: 1,
      name: "SuperFresh",
      website: "https://superfresh.com",
      phone: "87654321",
      email: "contact@superfresh.com",
      facebook: "facebook.superfresh",
      instagram: "instagram.superfresh",
      status: {
        interesting: false,
        seen: false,
        contacted: true
      },
    },
    {
      id: 2,
      name: "MegaShop",
      website: "https://megashop.com",
      phone: "89990000",
      email: "hello@megashop.com",
      facebook: "facebook.megashop",
      instagram: "instagram.megashop",
      status: {
        interesting: false,
        seen: true,
        contacted: true
      },
    },
    {
      id: 3,
      name: "FoodMart",
      website: "https://foodmart.com",
      phone: "80002233",
      email: "info@foodmart.com",
      facebook: "facebook.foodmart",
      instagram: "instagram.foodmart",
      status: {
        interesting: true,
        seen: true,
        contacted: false
      },
    },
    {
      id: 4,
      name: "FreshCorner",
      website: "https://freshcorner.com",
      phone: "88881111",
      email: "support@freshcorner.com",
      facebook: "facebook.freshcorner",
      instagram: "instagram.freshcorner",
      status: {
        interesting: false,
        seen: false,
        contacted: false
      },
    },
    {
      id: 5,
      name: "QuickMarket",
      website: "https://quickmarket.com",
      phone: "81234567",
      email: "hello@quickmarket.com",
      facebook: "facebook.quickmarket",
      instagram: "instagram.quickmarket",
      status: {
        interesting: false,
        seen: false,
        contacted: true
      },
    },
    {
      id: 6,
      name: "EcoStore",
      website: "https://ecostore.com",
      phone: "85553333",
      email: "contact@ecostore.com",
      facebook: "facebook.ecostore",
      instagram: "instagram.ecostore",
      status: {
        interesting: true,
        seen: false,
        contacted: false
      },
    },
    {
      id: 7,
      name: "ShopEase",
      website: "https://shopease.com",
      phone: "87776666",
      email: "info@shopease.com",
      facebook: "facebook.shopease",
      instagram: "instagram.shopease",
      status: {
        interesting: true,
        seen: true,
        contacted: true
      },
    },
    {
      id: 8,
      name: "MarketPlus",
      website: "https://marketplus.com",
      phone: "89991122",
      email: "contact@marketplus.com",
      facebook: "facebook.marketplus",
      instagram: "instagram.marketplus",
      status: {
        interesting: false,
        seen: false,
        contacted: true
      },
    },
    {
      id: 9,
      name: "UrbanGrocer",
      website: "https://urbangrocer.com",
      phone: "82223344",
      email: "hello@urbangrocer.com",
      facebook: "facebook.urbangrocer",
      instagram: "instagram.urbangrocer",
      status: {
        interesting: false,
        seen: true,
        contacted: false
      },
    },
    {
      id: 10,
      name: "EverydayShop",
      website: "https://everydayshop.com",
      phone: "84445555",
      email: "info@everydayshop.com",
      facebook: "facebook.everydayshop",
      instagram: "instagram.everydayshop",
      status: {
        interesting: true,
        seen: false,
        contacted: true
      },
    }
  ];

*/