import { useEffect, useRef, useState } from "react"
import MagnifingGlass from "../reusable/MagnifingGlass";
import OpenLink from '../reusable/OpenLink'
import { useParams } from "react-router-dom";
import { useGBatch } from "../../context/GBatchContext";
import { useKeys } from "../../context/KeysContext";

const ProspectTable = ({ prospectData }) => {
  const { getBatchById, updateAndSetProspect } = useGBatch()
  const {selectedInput, toggleSelectedInput} = useKeys()

  let eventRef = useRef(null)
  const {id: batchId} = useParams()
  const [prospect, setProspect] = useState(
    {
      id: '',
      name: '',
      website: '',
      phone: '',
      email: '',
      facebook: '',
      instagram: '',
      status: {
        interesting: null,
        seen: null,
        contacted: null
      }      
    }
  )

  const handleInputChange = (attribute, value) => {
    setProspect(prevState => {

      //revisa que NO tenga un '.' lo cual significa que es un top level attribute
      //si tiene un '.' prosigue al procedimiento adecuado
      if(!attribute.includes('.')){
        return  {...prevState, [attribute]: value}
      }

      const keys = attribute.split('.')
      const lastKey = keys.pop()
      const targetObject = keys.reduce((currentObj, key) => currentObj[key], prevState)
      const updatedObject = {
        ...prevState,
        [keys[0]]: {
          ...targetObject,
          [lastKey]: value
        }
      }

      return updatedObject
    })
  }
  const searchSocial = (name, social) => {
    const url = `https://www.google.com/search?q=${name}+${social}`

    window.open(url, '_blank')
  }
  const setIndicatorColor = prospect => {
    let color = 'black'

    if(prospect.status.interesting)
      color = 'green'
    else if(!prospect.status.interesting)
      color = 'red'

    if(prospect.status.contacted)
      color = 'orange'

    return color
  }
  const handleContact = () => {
    const batch = getBatchById(batchId)
    console.log(batch)
    const prospectHasWebsite = prospect.website ? true : false
    const message = prospectHasWebsite ? batch.mails.hasWebsite : batch.mails.hasNoWebsite
    
    navigator.clipboard.writeText(message.body)
    window.open(`https://wa.me/+506${prospect.phone[0].replace(' ', '')}`, '_blank')
  }
  const openLink = media => {
    switch(media){
      case 'website':
        window.open(prospect.website, '_blank')
        break
      case 'phone':
        handleContact()
        break
      case 'email':
        window.open(`mailto:${prospect.email}`, '_blank')
        break
    }
  }
  


  useEffect(() => {
    if(prospectData != null)
      setProspect(prospectData)
  }, [prospectData])
  useEffect(() => {
    if(eventRef.current){
      updateAndSetProspect(eventRef.current, batchId, prospect)
    }
  }, [prospect])
  useEffect(() => {
    const keyListener = e => {
      if(selectedInput) return
      eventRef.current = e
      if(e.key == 'w'){
        openLink('website')
      }
      if(e.key == 'p' || e.key == 'Enter'){
        openLink('phone')
      }
      if(e.key == 'e'){
        openLink('email')
      }
      if(e.key == 'f'){
        searchSocial(prospect.name, 'facebook')
      }
      if(e.key == 'n'){
        searchSocial(prospect.name, 'instagram')
      }
      if(e.key == 'i'){
        handleInputChange('status.interesting', !prospect.status.interesting)
      }
      if(e.key == 's'){
        handleInputChange('status.seen', !prospect.status.seen)
      }
      if(e.key == 'c'){
        handleInputChange('status.contacted', !prospect.status.contacted)
      }
      if(e.key == 'ArrowUp'){

      }
    }
    document.addEventListener('keyup', keyListener)

    return () => {
      document.removeEventListener('keyup', keyListener)
    }
  }, [prospect, selectedInput])

  return (
    <form 
      className="prospect-table"
    >
      <div className="row">
        <input 
          className="name"
          type="text"
          placeholder="Name"
          onChange={e => handleInputChange('name', e.target.value)}
          value={prospect.name}
          onSelect={toggleSelectedInput}
          onBlur={toggleSelectedInput}
        />        
        <div 
          style={{backgroundColor: setIndicatorColor(prospect)}}
          className="indicator"
        ></div>      
      </div>

      <div className="input-row">
        <input 
          type="text"
          placeholder="Website"
          onChange={e => handleInputChange('website', e.target.value)}
          value={prospect.website}
          onSelect={toggleSelectedInput}
          onBlur={toggleSelectedInput}      
        />
        <OpenLink onClick={() => openLink('website')} />
      </div>

      <div className="input-row">
        <input 
          type="text"
          placeholder="Phone"
          onChange={e => handleInputChange('phone', e.target.value)}
          value={prospect.phone}
          onSelect={toggleSelectedInput}
          onBlur={toggleSelectedInput}     
        />
        <OpenLink onClick={() => openLink('phone')} />
      </div>

      <div className="input-row">
        <input 
          type="text"
          placeholder="Email"
          onChange={e => handleInputChange('email', e.target.value)}
          value={prospect.email}
          onSelect={toggleSelectedInput}
          onBlur={toggleSelectedInput}
        />
        <OpenLink onClick={() => openLink('email')} />
      </div>

      <div className="input-row">
        <input 
          type="text"
          placeholder="Facebook"
          onChange={e => handleInputChange('facebook', e.target.value)}
          value={prospect.facebook}
          onSelect={toggleSelectedInput}
          onBlur={toggleSelectedInput}
        />
        <MagnifingGlass onClick={() => searchSocial(prospect.name, 'facebook')} />
      </div>

      <div className="input-row">
        <input 
          type="text"
          placeholder="Instagram"
          onChange={e => handleInputChange('instagram', e.target.value)}
          value={prospect.instagram}
          onSelect={toggleSelectedInput}
          onBlur={toggleSelectedInput}
        />
        <MagnifingGlass onClick={() => searchSocial(prospect.name, 'instagram')} />
      </div>

      <div className="input-row">
        <label>Interesting</label>
        <input 
          type="checkbox"
          checked={prospect.status.interesting === null ? false : prospect.status.interesting }
          onChange={e => handleInputChange('status.interesting', e.target.checked)}
        />
      </div>

      <div className="input-row">
        <label>Seen</label>
        <input 
          type="checkbox"
          checked={prospect.status.seen === null ? false : prospect.status.seen }
          onChange={e => handleInputChange('status.seen', e.target.checked)}
        />
      </div>

      <div className="input-row">
        <label>Contacted</label>
        <input 
          type="checkbox"
          checked={prospect.status.contacted === null ? false : prospect.status.contacted }
          onChange={e => handleInputChange('status.contacted', e.target.checked)}
        />
      </div>

      <button>Save</button>
    </form>
  );
}
 
export default ProspectTable;