import { useState, useEffect, useRef } from "react";
import { useKeys } from "../../context/KeysContext";

const ProspectList = ({ prospects, onProspectClick }) => {
  const {selectedInput} = useKeys()
  let eventRef = useRef(null)
  let listRef = useRef(null)
  let selectedRef = useRef(null)
  const [selected, setSelected] = useState(null)
  
  const setIndicatorColor = prospect => {
    let color = '#ffffff'

    if(prospect.status.interesting)
      color = 'green'
    else
      color = 'red'

    if(prospect.status.contacted)
      color = 'orange'

    return color
  }
  const handleOnProspectClick = prospect => {
    onProspectClick(prospect)
    setSelected(prospect)
  }
  const checkIsSelected = prospect => {
    if(!selected) return '';
    const isSelected = prospect._id === selected._id ? 'selected' : ''
    
    return isSelected
  }
  const scrollList = direction => {
    let scrollDistance = 181.81 * direction
    console.log(listRef.current.scrollTop)

    listRef.current.scrollTop += scrollDistance
  }
  const selectAdjacentProspect = direction => {
    for(let i = 0; i < prospects.length; i++){
      if(!selectedRef.current) {
        handleOnProspectClick(prospects[0])
        return
      }

      if(prospects[i]._id == selectedRef.current._id){
        const index = i + direction

        if(i > 0 && i < prospects.length - 1) scrollList(direction)
        if(index < 0 || index >= prospects.length) return

        handleOnProspectClick(prospects[index])
      }
    }
  }

  useEffect(() => {
    const keyListener = e => {
      let key = e.key
      eventRef.current = e
      if(key == 'ArrowUp' || key == 'ArrowLeft'){
        selectAdjacentProspect(-1)
      }
      if(key == 'ArrowDown' || key == 'ArrowRight'){
        selectAdjacentProspect(1)
      }
    }
    document.addEventListener('keyup', keyListener)

    return () => {
      document.removeEventListener('keyup', keyListener)
    }
  }, [prospects, selectedInput])

  useEffect(() => {
    selectedRef.current = selected
  }, [selected])

  return (
    <div 
      className="prospect-list"
      ref={listRef}
    >
      {prospects && prospects.map(prospect => (
        <div 
          style={{opacity: prospect.status.seen ? 0.5 : 1}}
          className={`prospect ${checkIsSelected(prospect)}`}
          key={prospect._id}
          onClick={() => handleOnProspectClick(prospect)}
        >
          <h2>{ prospect.name }</h2>
          <div 
            style={{backgroundColor: setIndicatorColor(prospect)}}
            className="indicator"
          ></div>
        </div>
      ))}
    </div>
  );
}
 
export default ProspectList;