import { createContext, useContext, useState, useEffect } from "react";
import { multiFetch } from "../utils/multiFetch";
import { useNavigate } from "react-router-dom";

const KeysContext = createContext();

export const useKeys = () => useContext(KeysContext);

export const KeysProvider = ({ children }) => {
  const [selectedInput, setSelectedInput] = useState(false)
  const [lastState, setLastState] = useState('blur')

  const toggleSelectedInput = e => {
    if ((e.type === 'blur'
      || e.type === 'select')
    && lastState != e.type) {
      const focusState = lastState == 'blur' ? 'select' : 'blur'
      setLastState(focusState)
      setSelectedInput(!selectedInput)
    }
  };

  return (
    <KeysContext.Provider value={{ selectedInput, toggleSelectedInput }}>
      {children}
    </KeysContext.Provider>
  );
};
