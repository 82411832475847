import React, { useEffect, useState } from "react";

const FilterMenu = ({ show, onChange }) => {
  // State variables for each checkbox
  const [seen, setSeen] = useState(false);
  const [interesting, setInteresting] = useState(false);
  const [contacted, setContacted] = useState(false);
  const [activeFilters, setActiveFilters] = useState(
    {
      seen: false,
      interesting: false,
      contacted: false,
    }
  )

  const handleFiltersChange = () => {
    let hasFilters = false
    const filters = {
      seen: activeFilters.seen ? seen : null,
      interesting: activeFilters.interesting ? interesting : null,
      contacted: activeFilters.contacted ? contacted : null,
    }

    for(let att in filters){
      if(filters[att] !== null) hasFilters = true;
    }

    onChange(hasFilters ? filters : null)
  }
  const toggleActiveFilters = attribute => {
    setActiveFilters(prevState => (
      {...prevState, [attribute]: !prevState[attribute]}
    ))
  }
  useEffect(() => {
    handleFiltersChange()
  }, [seen, interesting, contacted, activeFilters])

  return (
    <form
      className="filter-menu"
      style={{ display: show ? "flex" : "none" }}
    >
      <div>
        <input
          type="checkbox"
          checked={activeFilters.seen}
          onChange={() => toggleActiveFilters('seen')}
        />
        <label>Seen</label>
        <input
          style={{opacity: activeFilters.seen ? 1 : 0.5}}
          type="checkbox"
          checked={seen}
          onChange={e => setSeen(e.target.checked)}
        />
      </div>
      <div>
        <input
          type="checkbox"
          checked={activeFilters.interesting}
          onChange={() => toggleActiveFilters('interesting')}

        />
        <label>Interesting</label>
        <input
          style={{opacity: activeFilters.interesting ? 1 : 0.5}}
          type="checkbox"
          checked={interesting}
          onChange={e => setInteresting(e.target.checked)}
        />
      </div>
      <div>
        <input
          type="checkbox"
          checked={activeFilters.contacted}
          onChange={() => toggleActiveFilters('contacted')}
        />
        <label>Contacted</label>
        <input
          style={{opacity: activeFilters.contacted ? 1 : 0.5}}
          type="checkbox"
          checked={contacted}
          onChange={e => setContacted(e.target.checked)}
        />
      </div>
    </form>
  );
};

export default FilterMenu;