import { createContext, useContext, useState, useEffect } from "react";
import { multiFetch } from "../utils/multiFetch";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState(null)

  const handleAuthRedirect = res => {
    setAuth(res.authenticated)
    setUserId(res.userId)
    if(res.authenticated){
      navigate('/')
    }else {
      alert('Wrong Credentials')
    }
  }

  useEffect(() => {
    multiFetch('/is-logged')
    .then(res => {
      setAuth(res.authenticated)
      setUserId(res.userId)
    })
  }, [])

  useEffect(() => {
    if(auth === false) navigate('/login')
  }, [auth])

  return (
    <AuthContext.Provider value={{ auth, userId, handleAuthRedirect }}>
      {children}
    </AuthContext.Provider>
  );
};
