import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate()
  
  return (
    <div
      style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh', }}
    >
      <button onClick={() => navigate('/io')} >CManager</button>
      <button onClick={() => navigate('/gapi')} >GAPI</button>
    </div>
  );
}
 
export default Home;