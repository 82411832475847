const FilterIcon = ({ onClick }) => {
  return (
    <svg onClick={onClick} width="42" height="26" viewBox="0 0 42 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3H39" stroke="white" strokeWidth="5" strokeLinecap="round"/>
      <path d="M8 13H34" stroke="white" strokeWidth="5" strokeLinecap="round"/>
      <path d="M14 23H28" stroke="white" strokeWidth="5" strokeLinecap="round"/>
    </svg>

  );
}
 
export default FilterIcon;