import { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";

const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const wssUrl = process.env.REACT_APP_WSS_URL
  const { userId } = useAuth()
  const [newMessage, setNewMessage] = useState(null)
  let ws;

  const send = message => {
    ws.send(message)
  }

  useEffect(() => {
    if(!userId) return
    ws = new WebSocket(`${wssUrl}?userId=${userId}`)
    console.log(`${wssUrl}?userId=${userId}`)

    ws.addEventListener('open', () => {
      ws.send('Client Opened')
    })
  
    ws.addEventListener('message', ({data}) => {
      data = JSON.parse(data)
      setNewMessage(data)
    })

    return () => ws.close()
  }, [userId])

  return (
    <WebSocketContext.Provider value={{ newMessage, send }}>
      {children}
    </WebSocketContext.Provider>
  );
};
