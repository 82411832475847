import { useEffect, useState } from "react";
import { multiFetch } from "../../utils/multiFetch";
import DropdownArrow from "../reusable/DropdownArrow";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../../context/WebSocketContext";
import { useGBatch } from "../../context/GBatchContext";

const GapiForm = () => {
  const {newMessage: loadingStatus} = useWebSocket()
  const {setDBBatches, setCurrentBatch} = useGBatch()
  const navigate = useNavigate()

  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [radius, setRadius] = useState('');
  const [type, setType] = useState('');
  const [textQuery, setTextQuery] = useState('');
  const [pageAmount, setPageAmount] = useState('1')
  const [hasWebsite, setHasWebsite] = useState(true)
  const [message, setMessage] = useState({
    hasWebsite: {
      title: "",
      body: ""
    },
    hasNoWebsite: {
      title: "",
      body: ""
    },
  })

  const handleInputChange = (attribute, value) => {
    setMessage(prevState => {
      if(!attribute.includes('.')) return {...prevState, [attribute]: value}

      const keys = attribute.split('.')
      const lastKey = keys.pop()
      const targetObject = keys.reduce((currentObj, key) => currentObj[key], prevState)
      const updatedObject = {
        ...prevState,
        [keys[0]]: {
          ...targetObject,
          [lastKey]: value
        }
      }

      return updatedObject
    })
  }
  const postBatch = async e => {
    e.preventDefault()

    const query = {location, radius, type}
    const body = {batchName: name, query, pageAmount, message}

    let data = await multiFetch('/gapi/create-batch', body, 'POST')
    setCurrentBatch(data)
    setDBBatches()

    navigate(`/gapi/dashboard/${data._id}`)
  }

  return (
    <div className="form">
      <DropdownArrow onClick={() => navigate('/gapi')} />
      <form onSubmit={postBatch}>

        <div className="input-fields">
        <div className="google-query">
          <input 
            className="name"
            placeholder="Name"
            type="text"
            onChange={e => setName(e.target.value)}
            value={name}
          />
          <input 
            type="text"
            placeholder="text query"
            onChange={e => setTextQuery(e.target.value)}
            value={textQuery}
          />
          <input 
            type="text"
            placeholder="location"
            onChange={e => setLocation(e.target.value)}
            value={location}
          />
          <input 
            type="text"
            placeholder="radius"
            onChange={e => setRadius(e.target.value)}
            value={radius}
          />
          <input 
            type="number"
            placeholder="ammount of pages"
            onChange={e => setPageAmount(e.target.value)}
            value={pageAmount}
          />
        </div>
        <div className="message">
          <div className="row" style={{marginBottom: '50px'}}>
            <label style={{color: 'black', marginRight: '30px'}} htmlFor="">Has Website</label>
            <input 
              type="checkbox"
              onChange={() => setHasWebsite(!hasWebsite)}
              checked={hasWebsite}
          />
          </div>
          {hasWebsite && 
            <>
              <input 
                type="text"
                placeholder="Has Website Title"
                onChange={e => handleInputChange('hasWebsite.title', e.target.value)}
                value={message.hasWebsite.title}
              />
              <textarea
                style={{marginTop: '50px'}}
                placeholder="Has Website Message"
                onChange={e => handleInputChange('hasWebsite.body', e.target.value)}
                value={message.hasWebsite.body}
              ></textarea>
            </>
          }
          {!hasWebsite && 
            <>
              <input 
                type="text"
                placeholder="Has No Website Title"
                onChange={e => handleInputChange('hasNoWebsite.title', e.target.value)}
                value={message.hasNoWebsite.title}                
              />
              <textarea
                style={{marginTop: '50px'}}
                placeholder="Has No Website Message"
                onChange={e => handleInputChange('hasNoWebsite.body', e.target.value)}
                value={message.hasNoWebsite.body}
              ></textarea>
            </>
          }
        </div>
        </div>

        <button>Create Batch</button>

        {loadingStatus && 
          <>
            <span style={{color: 'black'}}>Created {loadingStatus.prospects} prospects</span>
            <span style={{color: 'black'}}>Created {loadingStatus.pages} pages</span>
          </>
        }
      </form>
    </div>
  );
}
 
export default GapiForm;