import { useEffect, useState } from 'react';
import '../../styles/gapi.css'
import Select from "../reusable/Select";
import { useNavigate } from "react-router-dom";
import { useGBatch } from '../../context/GBatchContext';

const GAPI = () => {
  const navigate = useNavigate()
  const {batches, currentBatch, setCurrentBatch} = useGBatch()
  const [batchOptions, setBatchOptions] = useState(null)
  const [currentBatchOption, setCurrentBatchOption] = useState(null)

  const handleChange = change => {
    const batch = batches.filter(b => b._id == change.id)[0]
    setCurrentBatch(batch)
  }
  const formatSelectOptions = batches => {
    if(!batches) {
      setBatchOptions([])
      return
    }
    let options = []

    batches.forEach(batch => {
      let batchOption = {
        name: batch.name,
        id: batch._id
      }
      
      options.push(batchOption)
    })

    setBatchOptions(options)
    setCurrentBatchOption(options.filter(o => o.id === currentBatch._id)[0])
  }

  useEffect(() => {formatSelectOptions(batches)}, [batches])

  return (
    <div className="gapi">
      <button
        onClick={() => navigate('/gapi/form')}
      >
        Create Batch
      </button>
      <div style={{ display: 'flex', gap: '50px', marginTop: '50px' }}>
        <button 
          onClick={() => navigate(`/gapi/dashboard/${currentBatch._id}`)}
        >See Batch</button>
        {(currentBatch && batchOptions) && 
          <Select 
            head={ currentBatchOption }
            selectOptions={ batchOptions }
            handleChange={change => handleChange(change)}
          />
        }
      </div>
    </div>
  );
}
 
export default GAPI;