import '../../styles/authenticate.css'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { multiFetch } from '../../utils/multiFetch';
import { useAuth } from '../../context/AuthContext';

const Login = () => {
  const { auth, handleAuthRedirect } = useAuth()
  const [model, setModel] = useState({
    username: '',
    password: ''
  });

  const setModelValues = (inputValue, inputField) => {
    setModel(prevState => (
      {...prevState, [inputField]: inputValue}
    ))
  }
  const postLogin = async e => {
    e.preventDefault();
    multiFetch('/post-login', model, 'POST')
    .then(res => {
      handleAuthRedirect(res)
    })
  }

  return (
    <div className="login">
      {auth !== null && 
        <>
          <p className="title">Ingresar</p>
          <form>
              <input onChange={e => setModelValues(e.target.value, 'username') } autoCapitalize="none" type="text" placeholder="Usuario" />
              <input onChange={e => setModelValues(e.target.value, 'password') } autoCapitalize="none" type="password" placeholder="Contrase&ntilde;a" />
              <Link to="/register" style={{marginBottom: '75px'}}>Crear Cuenta</Link>
              <button onClick={ postLogin }>Ingresar</button>
          </form>
        </>
      }
    </div>
  );
}
 
export default Login;