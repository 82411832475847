import { createContext, useContext, useState, useEffect } from "react";
import { multiFetch } from "../utils/multiFetch";
import { useAuth } from "./AuthContext";

const GBatchContext = createContext();

export const useGBatch = () => useContext(GBatchContext);

export const GBatchProvider = ({ children }) => {
  const { auth } = useAuth()
  const [batches, setBatches] = useState(null)
  const [currentBatch, setCurrentBatch] = useState(null)
 
  const GETBatch = async id => {
    const url = `/gapi/get-batch/${id}`
    let data;

    try {
      data = await multiFetch(url)
    }catch(e) {
      data = null
    }

    return data
  }
  const GETBatches = async () => {
    const url = '/gapi/get-batches'
    const data = await multiFetch(url)

    return data
  }
  const PUTProspect = async (e, batchId, prospect) => {
    e.preventDefault()

    const url = `/gapi/put-prospect/${batchId}`
    const data = await multiFetch(url, prospect, 'PUT')
    
    return data
  }
  const updateAndSetProspect = async (e, batchId, prospect) => {
    let updatedProspect = await PUTProspect(e, batchId, prospect)

    setCurrentBatch(prevState => (
      {
        ...prevState,
        prospects: prevState.prospects.map(p =>
          p._id === prospect._id
            ? {...p, ...updatedProspect}
            : p
        )
      }
    ))


    setBatches(prevState => 
      prevState.map(batch => 
        batch._id === batchId
          ? {
            ...batch,
            prospects: batch.prospects.map(p =>
              p._id === prospect._id
                ? {...p, ...updatedProspect}
                : p
            )
          }
          : batch
      )
    )
  }
  const setDBBatches = async () => {
    const DBBatches = await GETBatches()

    setBatches(DBBatches)
    setCurrentBatch(DBBatches[0])
  }
  const getBatchById = id => {
    const batch = batches.find(b => b._id == id)

    return batch
  }

  useEffect(() => {
    const runSetDBBatches = async () => {
      await setDBBatches()
    }

    runSetDBBatches()
  }, [auth])

  return (
    <GBatchContext.Provider value={{ batches, currentBatch, setCurrentBatch, setDBBatches, updateAndSetProspect, GETBatch, getBatchById }}>
      {children}
    </GBatchContext.Provider>
  );
};
