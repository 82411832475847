export const multiFetch = async (url, body, reqType) => {
  const baseUrl = process.env.REACT_APP_BASEURL
  let res = await fetch(baseUrl + url, {
    method: reqType ? reqType : 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: body ? JSON.stringify(body) : null
  })
  
  if(!res.ok){
    let err = await res.json()
    console.log(err.err)
    throw Error(`Couldn\'t fetch data boi: ${err.err}`)
  }
  
  return await res.json()
}
