import './styles/index.css';
import Home from './Home'
import CManager from './components/CManager/CManager'
import GAPI from './components/GAPI/GAPI'
import GapiForm from './components/GAPI/GapiForm';
import GapiDashboard from './components/GAPI/GapiDashboard';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Private from './components/auth/Private';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext';
import { GBatchProvider } from './context/GBatchContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { KeysProvider } from './context/KeysContext';

function App() {
  return(
    <div className="App">
      <Router>
        <KeysProvider>
          <AuthProvider>
            <WebSocketProvider>
              <GBatchProvider>
                <Routes>
                  <Route path='/' element={ <Private /> } >
                    <Route index element={ <Home /> } />
                  </Route>
                  <Route path='/gapi' element={ <Private /> }>
                    <Route index element={ <GAPI /> } />
                    <Route path='/gapi/form' element={ <GapiForm /> } />
                    <Route path='/gapi/dashboard/:id' element={ <GapiDashboard /> } />
                  </Route>
                  <Route path='/io' element={ <Private /> } >
                    <Route index element={ <CManager /> } />
                  </Route>
                  <Route path='/login' element={<Login />} />
                  <Route path='/register' element={<Register />} />
                </Routes>
              </GBatchProvider>
            </WebSocketProvider>
          </AuthProvider>
        </KeysProvider>
      </Router>
    </div>
  )
}

export default App;
