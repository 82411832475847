import '../../styles/Select.css'

const DropdownArrow = ({ onClick }) => {
  return (
    <svg onClick={onClick} className="dropdown-arrow" viewBox="0 0 394 224" width="394" height="224">
      <path fillRule="evenodd" clipRule="evenodd" d="M385.798 46.0919C396.342 35.5477 396.342 18.4523 385.798 7.90812C375.253 -2.63604 358.158 -2.63604 347.614 7.90812L197 158.522L46.3863 7.90829C35.8422 -2.63587 18.7467 -2.63587 8.20255 7.90829C-2.34161 18.4524 -2.3416 35.5479 8.20255 46.0921L177.908 215.797L177.908 215.798C188.452 226.342 205.548 226.342 216.092 215.798C216.113 215.776 216.135 215.755 216.156 215.733L385.798 46.0919Z" fill="#F3E8D0"/>
    </svg>
  );
}
 
export default DropdownArrow;
